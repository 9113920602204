import React, { Fragment, useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded'
import BathtubRoundedIcon from '@mui/icons-material/BathtubRounded'
import Typography from '@mui/material/Typography'

import CreateButton from '../components/create-button'
import DoseCard from '../components/dose-card'
import { useDoses, useDuplicateDosesToOtherDays } from '../hooks/use-dose'
import { DAYS_OF_WEEK } from '../constants'
import { Day } from '../types'
import CreateDoseForm from '../components/forms/dose/create'
import { getHours, getLengthInHours, printDoseTime } from '../helpers'

type Days = 1|2|3|4|5|6|0

function PlanDetails({
    planId,
}: {
    planId: string | undefined,
}) {

    const [selectedDay, setSelectedDay] = useState<Days>(1)
    const [add, setAdd] = useState<boolean>(false)

    const mutationDuplicate = useDuplicateDosesToOtherDays(planId, selectedDay)

    const { status, data, error } = useDoses(planId)

    const handleDaySelect = (day: Days) => {
        setAdd(false)
        setSelectedDay(day)
    }

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    function DayAvatar({
        day,
        selected,
    }: {
        day: Day,
        selected?: boolean,
    }) {
        return (
            <IconButton onClick={() => handleDaySelect(day.id)} sx={{ padding: 0.5 }}>
                <Avatar sx={{
                    color: 'primary.main',
                    bgcolor: 'customGreen.main',
                    fontSize: '1rem',
                    outline: selected ? '1px solid #001F3F' : 'none',
                }}>
                    {day.name}
                </Avatar>
            </IconButton>
        )
    }

    const filteredDoses = data.filter(dose => dose.day_of_week == selectedDay)

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginTop: 1,
                marginBottom: 2,
            }}>
                {DAYS_OF_WEEK.map(day => (
                    <DayAvatar key={day.id} day={day} selected={selectedDay == day.id} />
                ))}
            </Box>
            <Box display="flex" flexDirection="column" marginBottom={3}>
                <Box sx={{
                    flexGrow: 1,
                    position: 'relative',
                    minHeight: 24,
                }}>
                    {filteredDoses.map(dose => {
                        const startHour = getHours(dose.begins_at)
                        const lengthHours = getLengthInHours(dose.begins_at, dose.ends_at)
                        return(
                            <Box key={dose.id} sx={{
                                position: 'absolute',
                                left: ((startHour+(lengthHours/2))/0.24)+'%',
                                fontSize: '0.75rem',
                                transform: 'translate(-50%)',
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap',
                            }}>{printDoseTime(dose.begins_at, dose.ends_at)}</Box>
                        )
                    })}
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    backgroundColor: 'customGreen.main',
                    borderRadius: 10,
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: 24,
                }}>
                    {filteredDoses.map(dose => {
                        const startHour = getHours(dose.begins_at)
                        const lengthHours = getLengthInHours(dose.begins_at, dose.ends_at)

                        return(
                            <Box key={dose.id} sx={{
                                position: 'absolute',
                                left: (startHour/0.24)+'%',
                                width: (lengthHours/0.24)+'%',
                                height: '100%',
                                backgroundColor: 'customBlue.dark',
                            }}>
                            </Box>
                        )
                    })}
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    position: 'relative',
                    minHeight: 24,
                }}>
                    {filteredDoses.map(dose => {
                        const startHour = getHours(dose.begins_at)
                        const lengthHours = getLengthInHours(dose.begins_at, dose.ends_at)
                        return(
                            <Box key={dose.id} sx={{
                                position: 'absolute',
                                left: ((startHour+(lengthHours/2))/0.24)+'%',
                                fontSize: '0.75rem',
                                transform: 'translate(-50%)',
                                backgroundColor: 'white',
                                display: 'flex',
                                marginTop: 0.5,
                            }}>
                                {dose.dose_time_frame_dose_type_junctions.map((doseType, index) => (doseType.doses_count > 0 ?
                                    <Fragment key={index}>
                                        <Typography component="span" sx={{ fontSize: '0.8rem' }}>{doseType.doses_count}</Typography>
                                        {doseType.dose_type_id == 1 &&
                                            <ShowerRoundedIcon sx={{ marginRight: 1 }} fontSize='small'/>
                                        }
                                        {doseType.dose_type_id == 2 &&
                                            <BathtubRoundedIcon sx={{ marginRight: 1 }} fontSize='small'/>
                                        }
                                    </Fragment>
                                    : null
                                ))}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                {filteredDoses.map(dose => (
                    <DoseCard
                        key={dose.id}
                        dose={dose}
                    />
                ))}
            </Box>
            <Box display="flex" mt={2}>
                {!add &&
                    <CreateButton text='Přidat' onClick={() => setAdd(true)}/>
                }
                {add &&
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'customBlue.main',
                        padding: 2,
                        borderRadius: 5,
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignContent: 'center',
                            marginBottom: 1,
                        }}>
                            <Box sx={{
                                fontSize: '1.25rem',
                                flexGrow: 1,
                                color: 'primary.main',
                            }}>
                                Nový úsek spotřeby
                            </Box>
                            <Box>
                                <IconButton
                                    color="primary"
                                    aria-label="back"
                                    onClick={() => setAdd(false)}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <CreateDoseForm
                            appliancePlanId={planId}
                            selectedDay={selectedDay}
                            handleClose={() => setAdd(false)}
                        />
                    </Box>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Button
                        startIcon={<CalendarMonthRoundedIcon fontSize='large' />}
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                        onClick={() => {
                            mutationDuplicate.mutate()
                        }}
                        disabled={mutationDuplicate.isPending}
                        endIcon={mutationDuplicate.isPending? <CircularProgress/> : null }
                    >
                        Použít i pro další dny
                    </Button>
                </Box>
                {
                    mutationDuplicate.isError 
                    ? 
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{color: '#f70500'}}>
                                {mutationDuplicate.error.message}
                            </Box>
                        </Box>
                    :
                        null
                }
            </Box>
        </>
    )
}

export default PlanDetails
