import { Dayjs } from "dayjs"
import { DataDictionary } from "./helpers";

export interface EstateFormInput {
    id?: number,
    name: string,
    latitude: string,
    longitude: string,
    adress: string,
}

export interface Estate {
    id: number,
    name: string,
    latitude: string,
    longitude: string,
    distribution_electricity_id: number,
    optimalization_active: boolean,
    optimalization_disabled: boolean,
    adress: string,
}

export interface EstateGPS {
    latitude: string,
    longitude: string,
    error?: string,
}

export enum EnergyClass {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

export interface ApplianceFormInput {
    id?: number,
    // name: '',
    energyCapacity: number | string,
    inputPower: number | string,
    isDependentOnHdo: boolean,
    lossPower: number | string,
    // type: DeviceType,
    // blob
    dissipation: number | string,
    energyClass: EnergyClass,
    hotWaterMaxTemperature: number | string,
    hotWaterMinTemperature: number | string,
    priceWeight: number | string,
    roomTemperature: number | string,
    volume: number | string,
    // waterSourceTemperature: number | string, // value is set by backend (#158)
    temperatureWeight: number | string,
}

export interface Appliance {
    id: number,
    created_at: string,
    energy_capacity: string,
    estate_id: number,
    input_power: number,
    is_dependent_on_hdo: boolean,
    loss_power: number,
    updated_at: string,
    blob: {
        dissipation: number,
        energetic_class: EnergyClass,
        hot_water_max_temperature: number,
        hot_water_min_temperature: number,
        price_weight: number,
        room_temperature: number,
        temperature_weight: number,
        volume: number,
        water_source_temperature: number,
    }
    integrator_url: string,
    actual_temperature: number,
    actual_temperature_percentage: number,
}

export function getApplianceSchema() {
    const schema = new DataDictionary<Partial<ApplianceFormInput>, Appliance, Appliance['blob'] >()

    schema.add('id', 'id', 'number' )
    schema.add('energyCapacity','energy_capacity', 'number' )
    schema.add('inputPower', 'input_power', 'number' )
    schema.add('isDependentOnHdo', 'is_dependent_on_hdo', 'boolean' )
    schema.add('lossPower','loss_power', 'number' )

    // blob
    schema.addBlob('dissipation', 'dissipation','number' )
    schema.addBlob('energyClass','energetic_class','string') //EnergyClass', )
    schema.addBlob('hotWaterMaxTemperature','hot_water_max_temperature', 'number' )
    schema.addBlob('hotWaterMinTemperature', 'hot_water_min_temperature', 'number' )
    schema.addBlob('priceWeight','price_weight', 'number' )
    schema.addBlob('roomTemperature', 'room_temperature', 'number' )
    schema.addBlob('volume', 'volume','number' )
    // schema.addBlob('waterSourceTemperature', 'water_source_temperature','number' )  //  value is set by backend (#158)
    schema.addBlob('temperatureWeight','temperature_weight', 'number' )

    return schema;
}


export interface RenewableSourceFormInput {
    id?: number,
    name: string,
    power: number | string,
    declination: number | string,
    azimuth: number | string,
    // type: DeviceType,
}

export interface RenewableSource {
    id: number,
    azimuth: number,
    created_at: string,
    declination: number,
    estate_id: number,
    name: string,
    power: number,
    updated_at: string,
}

export interface AppliancePlanFormInput {
    id?: number,
    name: string,
    active: boolean,
}

export interface AppliancePlan {
    id: number,
    name: string,
    active: boolean,
}

export interface DoseFormInput {
    id?: number,
    begins_at: Dayjs,
    ends_at: Dayjs,
    shower_count: string | number,
    bath_count: string | number,
}

interface DoseType {
    dose_type_id: 1 | 2,
    doses_count: number,
}

export interface Dose {
    id: number,
    begins_at: string,
    ends_at: string,
    day_of_week: number,
    count: number,
    dose_time_frame_dose_type_junctions: Array<DoseType>,
    appliance_plan_id: number,
}

export interface PlanOverrideFormInput {
    id?: number,
    begins_at: Dayjs,
    ends_at: Dayjs,
    type: PlanOverrideType,
}

export interface PlanOverride {
    id: number,
    begins_at: string,
    ends_at: string,
    type: PlanOverrideType,
}

export interface ApplianceControlFormInput {
    id?: number,
    device_id: string,
    client_id?: string,
    client_secret?: string,
    cloud_server?: string,
    cloud_key?: string,
    type: ApplianceControlType,
}

export interface ApplianceControl {
    id: number,
    device_id: string,
    type: ApplianceControlType,
    appliance_id: number,
    blob: {
        client_id?: string,
        client_secret?: string,
        cloud_server?: string,
        cloud_key?: string,
    }
}

export interface PowerElectricityFormInput {
    id?: number,
    power_fees_amount_cents: string | number,
    power_electricity_amount_cents?: string | number,
    type: PowerElectricityType,
}

export interface PowerElectricity {
    id: number,
    estate_id: number,
    type: PowerElectricityType,
    power_electricity: Array<AmountObject>,
    power_fees: Array<AmountObject>,
}

interface AmountObject {
    amount_cents: number,
}

export interface SellElectricityFormInput {
    id?: number,
    sell_fees_amount_cents: string | number,
    sell_amount_cents?: string | number,
    cant_be_negative?: boolean,
    type: SellElectricityType,
}

export interface SellElectricity {
    id: number,
    estate_id: number,
    type: SellElectricityType,
    sell_electricity: Array<AmountObject>,
    sell_fees: Array<AmountObject>,
    cant_be_negative?: boolean,
}

export type DistributionElectricity_Supplier = 'ČEZ' | 'EG.D' | 'PRE'

export function supplierHasHDO(
    supplier: DistributionElectricity_Supplier | undefined | '' // forms have strings and undefined values
) {
    return supplier === 'PRE' || supplier === 'EG.D'
}

export function supplierHasEAN(
    supplier: DistributionElectricity_Supplier | undefined | '' // forms have strings and undefined values
) {
    return supplier === 'ČEZ'
}

export type DistributionElectricity_DistributionRate = (
    //
    // Zdroj dat: https://chytrybojler.cz/tutorials/
    //
    // TODO: take this data from backend, which uses excel as data source atm
    //
    'D01d' |
    'D02d' |
    'D25d' |
    'D26d' |
    'D27d' |
    'D35d' |
    'D45d' |
    'D56d' |
    'D57d' |
    'D61d'
)

export const DISTRIBUTION_FORM_SUPPLIERS: {
    id: DistributionElectricity_Supplier,
    name: string
}[] = [
    { name: 'ČEZ', id: 'ČEZ' },
    { name: 'EON (EG.D)', id: 'EG.D' },
    { name: 'PRE', id: 'PRE' },
]

export const DISTRIBUTION_RATE_TABLE: {
    id: DistributionElectricity_DistributionRate,
    name: string
}[] = [
    //
    // Zdroj dat: https://chytrybojler.cz/tutorials/
    //
    { name: 'D01d', id: 'D01d' },
    { name: 'D02d', id: 'D02d' },
    { name: 'D25d', id: 'D25d' },
    { name: 'D26d', id: 'D26d' },
    { name: 'D27d', id: 'D27d' },
    { name: 'D35d', id: 'D35d' },
    { name: 'D45d', id: 'D45d' },
    { name: 'D56d', id: 'D56d' },
    { name: 'D57d', id: 'D57d' },
    { name: 'D61d', id: 'D61d' },
]

export interface DistributionElectricityFormInput {
    id?: number,
    supplier?: DistributionElectricity_Supplier | '',
    distribution_rate?: DistributionElectricity_DistributionRate | '',
    has_manual_hdo: boolean,
    low_price_cents: string | number,
    high_price_cents: string | number,
    signal_code?: string,
    ean?: string;
}

export interface DistributionElectricity {
    id: number,
    supplier?: DistributionElectricity_Supplier,
    distribution_rate?: DistributionElectricity_DistributionRate,
    has_manual_hdo: boolean,
    low_price_cents: number,
    high_price_cents: number,
    signal_code?: string,
    ean?: string,
    estate_id: number;
    warnings?: string,
}

export function getDistributionElectricitySchema() {
    const schema = new DataDictionary<Partial<DistributionElectricityFormInput>, DistributionElectricity, {}>()

    schema.add('id', 'id', 'number' )
    schema.add('supplier','supplier', 'string' )
    schema.add('distribution_rate', 'distribution_rate', 'string' ) // rename tarriff to distribution_rate (#225)
    schema.add('has_manual_hdo', 'has_manual_hdo', 'boolean' )
    schema.add('low_price_cents', 'low_price_cents','number' )
    schema.add('high_price_cents', 'high_price_cents','number' )
    schema.add('signal_code','signal_code', 'string' )
    schema.add('ean', 'ean','string' )

    return schema;
}

export enum DeviceType {
    boiler = 'Appliance::Boiler',
    pv = 'RenewableSource::Solar',
}

export enum PlanOverrideType {
    away = 'AppliancePlanOverride::Away',
    party = 'AppliancePlanOverride::Party',
}

export enum ApplianceControlType {
    tuya = 'ApplianceControl::TuyaPlug',
    shelly = 'ApplianceControl::ShellyPlug',
}

export enum PowerElectricityType {
    spot = 'PowerElectricity::Spot',
    flat = 'PowerElectricity::Flat',
}

export enum SellElectricityType {
    spot = 'SellElectricity::Spot',
    flat = 'SellElectricity::Flat',
    null = 'SellElectricity::Null',
}

export type Day = {
    id: 1|2|3|4|5|6|0,
    name: string,
}

export type PlugType = {
    id: ApplianceControlType,
    name: string,
}

export type PowerElectricityOption = {
    id: PowerElectricityType,
    name: string,
}

export type SellElectricityOption = {
    id: SellElectricityType,
    name: string,
}

export interface HdoFormInput {
    id?: number,
    begins_at: Dayjs,
    ends_at: Dayjs,
}

export interface Hdo {
    id: number,
    begins_at: string,
    ends_at: string,
    day_of_week: number,
    distribution_electricity_id: number,
}

export interface EstateIsReady {
    is_ready: boolean,
    error: string | null | undefined,
}

export interface CustomDataWithError {
    error: string | null | undefined,
}

export interface BasicConsumption {
    id: number,
    persons_count: number,
    for_showering: boolean,
    for_hand_wash: boolean,
    for_hand_dish_washing: boolean,
    for_dishwasher: boolean,
    for_cleaning: boolean,
    for_other_usage: string,
    appliance_id: number,
}


export interface BasicConsumptionInput {
    id?: number,
    personsCount: number,
    forShowering: boolean,
    forHandWash: boolean,
    forHandDishWashing: boolean,
    forDishwasher: boolean,
    forCleaning: boolean,
    forOtherUsage: string,
    applianceId: number,
}

export const CONSUMPTION_TEXTS: { [K in keyof Pick<BasicConsumptionInput, 'forShowering' | 'forHandWash' | 'forHandDishWashing' | 'forDishwasher' | 'forOtherUsage'>]: { label: string, hint: string } } = {
    forShowering: {
        label: "Sprchování/koupání",
        hint: '',
    },
    forHandWash: {
        label: "Mytí rukou a další běžná menší spotřeba",
        hint: 'Např. občasné mytí nádobí, vytírání, úklid...',
    },
    forHandDishWashing: {
        label: "Mytí všeho nádobí",
        hint: 'Tuto možnost zaškrtněte, pokud nevlastníte myčku nádobí a všechno nádobí umýváte v teplé vodě z tohoto bojleru',
    },
    forDishwasher: {
        label: "Mám speciální myčku napojenou na bojler",
        hint: 'Vaše myčka není napojená na studenou vodu, ale na teplou vodu z tohoto bojleru.',
    },
    forOtherUsage: {
        label: 'Zvláštní požadavky',
        hint: 'Např. třikrát týdně sprchování psa.',
    },
}
