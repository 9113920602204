import React from 'react'
import { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Button, { buttonClasses } from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import packageJson from './../../../package.json'

import StatusButton from '../components/status-button'

function BatteryView() {

    return (
        <>
            <Container maxWidth="sm">
                <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            component={Link}
                            to="/"
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        Bateriové úložiště
                    </Box>
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="settings"
                            component={Link}
                            to="/"
                        >
                            <SettingsRoundedIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 1.5,
                    marginTop: 1,
                    marginBottom: 2,
                }}>
                    <StatusButton
                        name='Můj plán 1'
                        status='Zapnutý'
                        icon='plan'
                    />
                    <StatusButton
                        name='Party režim'
                        status='Vypnutý'
                        icon='party'
                    />
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Button
                        startIcon={<CalendarMonthRoundedIcon fontSize='large' />}
                        endIcon={<ArrowDropDownRoundedIcon fontSize='inherit' />}
                        size='large'
                        sx={{
                            fontSize: '1.2rem',
                            fontWeight: 400,
                            [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
                                fontSize: '1.5rem',
                            }
                        }}
                    >
                        Můj plán 1
                    </Button>
                    <Box>
                        <IconButton color="primary" aria-label="delete">
                            <EditRoundedIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                }}>
                    <Button
                        startIcon={<HistoryRoundedIcon fontSize='large' />}
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                    >
                        Historie
                    </Button>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}

export default BatteryView
