import {
    ApplianceControlType,
    type PlugType,
    type Day,
    PowerElectricityType,
    PowerElectricityOption,
    SellElectricityType,
    SellElectricityOption,
} from "./types"

export const DAYS_OF_WEEK: Array<Day> = [
    {id: 1, name: 'Po'},
    {id: 2, name: 'Út'},
    {id: 3, name: 'St'},
    {id: 4, name: 'Čt'},
    {id: 5, name: 'Pá'},
    {id: 6, name: 'So'},
    {id: 0, name: 'Ne'}
]

export const TIMES_IN_PICKER: Array<string> = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "23:59",
]

export const DOSES_IN_PICKER: Array<number> = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
]

export const PLUG_TYPES: Array<PlugType> = [
    {
        id: ApplianceControlType.tuya,
        name: 'Tuya',
    },
    {
        id: ApplianceControlType.shelly,
        name: 'Shelly',
    },
]

export const POWER_ELECTRICITY_TYPES: Array<PowerElectricityOption> = [
    {
        id: PowerElectricityType.flat, // this is most common option, so we'll make it first
        name: 'Flat',
    },
    {
        id: PowerElectricityType.spot,
        name: 'Spot',
    },
]

export const SELL_ELECTRICITY_TYPES: Array<SellElectricityOption> = [
    {
        id: SellElectricityType.spot,
        name: 'Spot',
    },
    {
        id: SellElectricityType.flat,
        name: 'Flat',
    },
    {
        id: SellElectricityType.null,
        name: 'Neurčeno',
    },
]
