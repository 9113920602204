import React from "react"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined'
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded"
import Box from "@mui/material/Box"

import { FONT_WEIGHT } from "../../components"

// Alternative red colors
// #f70500
// #ff5555
// #181818
// #c53c34 #f9eceb


export function ErrorMessage({ title, children }: { title?: React.ReactNode, children: React.ReactNode }) {

    if (title) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                // border: '#c53c34',
                // backgroundColor: '#f9eceb',
                alignItems: 'center',
                // padding: '1rem 2.5rem 1rem 1rem',
                // padding: '1rem 1.5rem 1rem',
                padding: '0', //'1rem 1.5rem 1rem',
                borderRadius: '1.125rem',
            }}>
                {/* <ErrorOutlineRoundedIcon /> */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '.5rem',
                        // color:'#c53c34' ... ok
                        color: '#f70500',
                    }}
                >
                    {
                        title && (
                            <Box sx={{
                                fontWeight: FONT_WEIGHT.medium,
                                fontSize: '18px',
                            }}>{title}</Box>
                        )
                    }
                    <Box>{children}</Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '.75em',
        }}>
            <ErrorOutlineRoundedIcon sx={{ color: '#f70500' }} />
            <Box>{children}</Box>
        </Box>
    )
}
