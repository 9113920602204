import React, { useEffect } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
    type ApplianceFormInput,
    BasicConsumptionInput,
    EnergyClass
} from "../../../types"
import { useCreateAppliance } from "../../../hooks/use-appliance"
import CustomButton from "../../custom-button"
import FormRow from "../helpers/form-row"
import { useCreateBasicConsumption } from "../../../hooks/use-basic-consumption"
import { useDebug } from "../../../views/debug";
import { useNavigate } from "react-router-dom"
import { ApplianceUsages } from "./components/appliance-usages"

type FormInputs = ApplianceFormInput & Pick<BasicConsumptionInput, (
    'personsCount' |
    'forCleaning' |
    'forHandDishWashing' |
    'forOtherUsage' |
    'forShowering' |
    'forDishwasher' |
    'forHandWash'
)>

export default function CreateApplianceForm({
    estateId,
}: {
    estateId: string | undefined,
}) {

    const { control, handleSubmit, getValues } = useForm<FormInputs>({
        defaultValues: {
            // name: '',
            energyCapacity: '',
            inputPower: '',
            isDependentOnHdo: true,
            lossPower: '',
            // blob
            dissipation: '',
            energyClass: EnergyClass.C,
            hotWaterMaxTemperature: '60',
            hotWaterMinTemperature: '40',
            priceWeight: '',
            roomTemperature: '22',
            volume: '',
            // waterSourceTemperature: '10',  // value is set by backend (#158)
            temperatureWeight: '',
            personsCount: 2,
            forCleaning: false,
            forDishwasher: false,
            forHandDishWashing: false,
            forHandWash: false,
            forShowering: false,
            forOtherUsage: '',
        },
    })

    const debug = useDebug({ name: 'appliance / create ' })

    const [applianceId, setApplianceId] = React.useState(0)

    //
    // 3. open newly created appliance
    //
    const navigate = useNavigate()
    const mutationBasicConsumption = useCreateBasicConsumption(applianceId, () => {
        debug.log('redirecting to ', estateId, applianceId)
        navigate(`/estate/${estateId}/appliance/${applianceId}`)
    })

    //
    //  2. create appliance's basic consumption data
    //
    useEffect(() => {
        debug.log('use effect')
        if (applianceId) {

            debug.log('added consumption!')

            mutationBasicConsumption.mutate({
                personsCount: getValues('personsCount'),
                forCleaning: getValues('forCleaning'),
                forHandDishWashing: getValues('forHandDishWashing'),
                forOtherUsage: getValues('forOtherUsage'),
                forShowering: getValues('forShowering'),
                forDishwasher: getValues('forDishwasher'),
                forHandWash: getValues('forHandWash'),
                applianceId: applianceId,
            })
        }
    }, [applianceId])

    //
    //  1. create appliance
    //
    const mutation = useCreateAppliance(estateId, false, (newApplianceId) => {
        debug.log('created appliance!')
        setApplianceId(newApplianceId)
    })

    const onSubmit: SubmitHandler<FormInputs> = data => {
        debug.log('submitted!')
        mutation.mutate(data)
    }

    const ENERGY_CLASS_ARRAY = Object.keys(EnergyClass) as Array<keyof typeof EnergyClass>

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <Controller
                        name="volume"
                        control={control}
                        render={({ field }) => <TextField id="appliance-volume" label="Objem [l]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow>
                {/* <FormRow>
                    <Controller
                        name="energyCapacity"
                        control={control}
                        render={({ field }) => <TextField id="appliance-energy-capacity" label="Kapacita energie" variant="standard" required {...field} />}
                    />
                    <Controller
                        name="lossPower"
                        control={control}
                        render={({ field }) => <TextField id="appliance-loss-power" label="Výkonová ztráta" variant="standard" type="number" required {...field} />}
                    />
                </FormRow> */}
                {/* Temporarily hidden - sending always true */}
                {/* <Box mt={1}>
                    <FormControlLabel control={
                        <Controller
                        name="isDependentOnHdo"
                        control={control}
                        render={({ field }) =>
                            <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                            />
                        }
                    />
                    } label="Závislý na HDO" />
                </Box> */}
                <FormRow>
                    {/* <Controller
                        name="dissipation"
                        control={control}
                        render={({ field }) => <TextField id="appliance-dissipation" label="Disipace" variant="standard" required {...field} />}
                    /> */}
                    <Controller
                        name="inputPower"
                        control={control}
                        render={({ field }) => <TextField id="appliance-input-power" label="Příkon [W]" variant="standard" type="number" required {...field} />}
                    />
                    <FormControl variant="standard" sx={{ minWidth: 130 }}>
                        <InputLabel id="appliance-energy-class-label">Energetická třída</InputLabel>
                        <Controller
                            name="energyClass"
                            control={control}
                            render={({ field }) =>
                                <Select
                                    labelId="appliance-energy-class-label"
                                    id="appliance-energy-class"
                                    {...field}
                                >
                                    {ENERGY_CLASS_ARRAY.map(energyClass => (
                                        <MenuItem key={energyClass} value={energyClass}>{energyClass}</MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    </FormControl>
                </FormRow>
                <FormRow>
                    {/* <Controller
                        name="hotWaterMinTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-hot-water-min-temperature" label="Min. teplota horké vody [°C]" variant="standard" type="number" required {...field} />}
                    /> */}
                    <Controller
                        name="hotWaterMaxTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-hot-water-max-temperature" label="Max. teplota horké vody [°C]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow>
                {/*
                 value is set by backend (#158)

                <FormRow>
                    <Controller
                        name="waterSourceTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-water-source-temperature" label="Teplota zdroje vody [°C]" variant="standard" type="number" required {...field} />}
                    />
                    <Controller
                        name="roomTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-room-temperature" label="Teplota místnosti bojleru [°C]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow>
                */}
                {/* <FormRow>
                    
                    <Controller
                        name="priceWeight"
                        control={control}
                        render={({ field }) => <TextField id="appliance-price-weight" label="Cenový koeficient" variant="standard" type="number" required {...field} />}
                    />
                    <Controller
                        name="temperatureWeight"
                        control={control}
                        render={({ field }) => <TextField id="appliance-temperature-weight" label="Koeficient teploty" variant="standard" type="number" required {...field} />}
                    />
                </FormRow> */}

                {/*  */}

                <FormRow>
                    <Controller
                        name="personsCount"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Počet osob co používá tento bojler"
                                variant="standard"
                                type="number"
                                // id="persons-count"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormRow>

                <ApplianceUsages control={control} />

                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Vytvořit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}

