import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { BottomButtons, FormControlWithLabelWrapper } from "../wizard-common";
import {
    PowerElectricity,
    PowerElectricityFormInput,
    PowerElectricityType
} from "../../../../types";
import { convertFromKiloWattHoursPrice } from "../../../../helpers";
import { POWER_ELECTRICITY_TYPES } from "../../../../constants";
import {
    useCreatePowerElectricity,
    usePowerElectricities,
    useUpdatePowerElectricity
} from "../../../../hooks/use-power-electricity";
import { PreviousStepButton } from "../../components";
import CustomButton from "../../../../components/custom-button";
import { StepName } from "../../types";

export function BuyingOfElectricity({
    estateId, goBack, goToStep
}: {
    estateId: number,
    goBack: () => void,
    goToStep: (stepName: StepName) => void,
}) {

    const { status, data, error } = usePowerElectricities(estateId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    // There is supposed to be only one value it seems @ DistributionElectricityList()

    return (
        <>
            {data.length > 0 ?
                <Update  powerElectricity={data[0]} goBack={goBack} goToStep={goToStep} />
                :
                <Create  estateId={estateId} goBack={goBack} goToStep={goToStep}/>
            }
        </>
    )
}

function Create({
    estateId,
    goBack,
    goToStep,
}: {
    goToStep: (stepName: StepName) => void,
    estateId: number,
    goBack: () => void,
}) {

    const mutation = useCreatePowerElectricity(estateId, () => {
        goToStep('step8')
    })

    const onSubmit: SubmitHandler<PowerElectricityFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <Form
            goBack={goBack}
            onSubmit={onSubmit}
            disableSubmit={mutation.isPending}
        />
    )
}

function Update({
    powerElectricity,
    goBack,
    goToStep,
}: {
    powerElectricity: PowerElectricity,
    goBack: () => void,
    goToStep: (stepName: StepName) => void,
}) {
    const estateId = powerElectricity.estate_id
    const mutation = useUpdatePowerElectricity(powerElectricity.id, estateId, () => {
        goToStep('step8') // @fixme maybe pull those internal steps out to Wizard component?
    })

    const onSubmit: SubmitHandler<PowerElectricityFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <Form
            powerElectricity={powerElectricity}
            goBack={goBack}
            onSubmit={onSubmit}
            disableSubmit={mutation.isPending}
        />
    )
}

function Form({
    onSubmit,
    powerElectricity, // initial data
    goBack,
    disableSubmit
}: {
    onSubmit: SubmitHandler<PowerElectricityFormInput>,
    powerElectricity?: PowerElectricity,
    goBack: () => void,
    disableSubmit?: boolean,
}) {
    const power_fees_amount_cents = powerElectricity && powerElectricity.power_fees.length > 0 ?
        convertFromKiloWattHoursPrice(powerElectricity.power_fees[powerElectricity?.power_fees.length - 1].amount_cents) : null

    const power_electricity_amount_cents = powerElectricity && powerElectricity.power_electricity.length > 0 ?
        convertFromKiloWattHoursPrice(powerElectricity.power_electricity[powerElectricity?.power_electricity.length - 1].amount_cents) : null

    const { control, watch, handleSubmit } = useForm({
        defaultValues: {
            type: powerElectricity?.type ?? PowerElectricityType.flat,
            power_fees_amount_cents: power_fees_amount_cents ?? 1000,
            power_electricity_amount_cents: power_electricity_amount_cents ?? 550,
        },
    })

    const names = {
        [PowerElectricityType.flat]: 'Platím pevnou (fixní) částku za MWh ',
        [PowerElectricityType.spot]: 'Platím podle spotových cen',
    }

    const typeWatch = watch('type')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
            }}>

                <FormControlWithLabelWrapper
                    label={"Jak se vám počítá cena elektřiny?"}
                >
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                // labelId="appliance-energy-class-label"
                                label="Typ plánu"
                                // id="appliance-energy-class"
                                {...field}
                            >
                                {POWER_ELECTRICITY_TYPES.map(powerElType => (
                                    <MenuItem key={powerElType.id} value={powerElType.id}>{names[powerElType.id]}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControlWithLabelWrapper>

                {typeWatch == PowerElectricityType.spot ?
                    <FormControlWithLabelWrapper
                        label={"Poplatky"}
                        // hint={'Pokud nevíte, nechte třídu C.'}
                    >
                        <Controller
                            name="power_fees_amount_cents"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    id="appliance-control-client-id"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                    }}
                                    variant="standard"
                                    type="number"
                                    required
                                    {...field}
                                />}
                        />
                    </FormControlWithLabelWrapper>
                : null}

                {typeWatch == PowerElectricityType.flat ?
                    <FormControlWithLabelWrapper
                        label={"Cena elektřiny"}
                    >
                        <Controller
                            name="power_electricity_amount_cents"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    id="appliance-control-client-id"
                                    //label="Poplatky"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                    }}
                                    variant="standard"
                                    type="number"
                                    required
                                    {...field}
                                />}
                        />
                    </FormControlWithLabelWrapper>
                : null}

                <BottomButtons>
                    <PreviousStepButton onClick={() => {
                        goBack()
                    }}>
                        Zpět
                    </PreviousStepButton>

                    <CustomButton
                        // text="Vytvořit"
                        text="Pokračovat"
                        type="submit"
                        disabled={disableSubmit}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}
