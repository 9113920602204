import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import CreateButton from '../create-button'
import HdoCard from '../hdo-card'
import { useHdos } from '../../hooks/use-hdo'
import { DAYS_OF_WEEK } from '../../constants'
import { Day } from '../../types'
import CreateHdoForm from '../forms/hdo/create'
import { getHours, getLengthInHours, printDoseTime } from '../../helpers'

function HdoList({
    distributionElectricityId,
} : {
    distributionElectricityId: string | number | undefined,
}) {

    const [selectedDay, setSelectedDay] = useState<1|2|3|4|5|6|0>(1)
    const [add, setAdd] = useState<boolean>(false)

    const { status, data, error } = useHdos(distributionElectricityId)

    const handleDaySelect = (day: 1|2|3|4|5|6|0) => {
        setAdd(false)
        setSelectedDay(day)
    }

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    function DayAvatar({
        day,
        selected,
    }: {
        day: Day,
        selected?: boolean,
    }) {
        return (
            <IconButton onClick={() => handleDaySelect(day.id)} sx={{ padding: 0.5 }}>
                <Avatar sx={{
                    color: 'primary.main',
                    bgcolor: 'customBlue.main',
                    fontSize: '1rem',
                    outline: selected ? '1px solid #001F3F' : 'none',
                }}>
                    {day.name}
                </Avatar>
            </IconButton>
        )
    }

    const filteredHdos = data.filter(hdo => hdo.day_of_week == selectedDay)

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginTop: 1,
                marginBottom: 2,
            }}>
                {DAYS_OF_WEEK.map(day => (
                    <DayAvatar key={day.id} day={day} selected={selectedDay == day.id} />
                ))}
            </Box>
            <Box display="flex" flexDirection="column" marginBottom={3}>
                <Box sx={{
                    flexGrow: 1,
                    position: 'relative',
                    minHeight: 24,
                }}>
                    {filteredHdos.map(hdo => {
                        const startHour = getHours(hdo.begins_at)
                        const lengthHours = getLengthInHours(hdo.begins_at, hdo.ends_at)
                        return(
                            <Box key={hdo.id} sx={{
                                position: 'absolute',
                                left: ((startHour+(lengthHours/2))/0.24)+'%',
                                fontSize: '0.75rem',
                                transform: 'translate(-50%)',
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap',
                            }}>{printDoseTime(hdo.begins_at, hdo.ends_at)}</Box>
                        )
                    })}
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    backgroundColor: 'customBlue.main',
                    borderRadius: 10,
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: 24,
                }}>
                    {filteredHdos.map(hdo => {
                        const startHour = getHours(hdo.begins_at)
                        const lengthHours = getLengthInHours(hdo.begins_at, hdo.ends_at)

                        return(
                            <Box key={hdo.id} sx={{
                                position: 'absolute',
                                left: (startHour/0.24)+'%',
                                width: (lengthHours/0.24)+'%',
                                height: '100%',
                                backgroundColor: 'customBeige.dark',
                            }}>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Box sx={{
                color: 'primary.main',
                fontWeight: 400,
                marginTop: '1.5rem',
                marginBottom: '0.5rem',
            }}>
                Časové úseky nízkého tarifu
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                {filteredHdos.map(hdo => (
                    <HdoCard
                        key={hdo.id}
                        hdo={hdo}
                    />
                ))}
            </Box>
            <Box display="flex" mt={2}>
                {!add &&
                    <CreateButton text='Přidat' onClick={() => setAdd(true)}/>
                }
                {add &&
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'customBeige.light',
                        padding: 2,
                        borderRadius: 5,
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignContent: 'center',
                            marginBottom: 1,
                        }}>
                            <Box sx={{
                                fontSize: '1.25rem',
                                flexGrow: 1,
                                color: 'primary.main',
                            }}>
                                Nový úsek nízkého tarifu
                            </Box>
                            <Box>
                                <IconButton
                                    color="primary"
                                    aria-label="back"
                                    onClick={() => setAdd(false)}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <CreateHdoForm
                            distributionElectricityId={distributionElectricityId}
                            selectedDay={selectedDay}
                            handleClose={() => setAdd(false)}
                        />
                    </Box>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
            }}>
                <Button
                    startIcon={<CalendarMonthRoundedIcon fontSize='large' />}
                    size='large'
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 400,
                    }}
                    disabled
                >
                    Použít i pro další dny
                </Button>
            </Box>
        </>
    )
}

export default HdoList
