import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import packageJson from '../../../../package.json'
import { WizardStep1 } from "./steps/wizard-step1";
import { StepName } from "./types";
import { WizardStep2 } from "./steps/wizard-step2";
import { WizardStep3 } from "./steps/wizard-step3";
import { WizardStep7 } from "./steps/wizard-step7";
import { WizardStep8 } from "./steps/wizard-step8";
import { FONT_WEIGHT } from "./components";
import { WizardStep9 } from "./steps/wizard-step9";
import { NewAppliancePlanStep } from "./steps/wizard-step10";
import { DebugWizard, useDebug } from '../debug';
import { useAppliance } from '../../hooks/use-appliance';
import { WizardStep2Prepare } from "./steps/wizard-step2-prepare";
import { WizardStepWelcome } from './steps/wizard-step-welcome';

export type WizardMode = 'create_estate' | 'edit_appliance'

function StepProgress(props: {
    availableSteps: StepName[]
    currentStep: StepName
}) {
    // steps are numbered from 1

    const stepNumberFromStepName = (stepName: StepName): number => {
        const currentStepAsString: string = stepName.replace(/[^0-9]+/, '')
        const currentStep = parseInt(currentStepAsString)
        return (
            currentStep
        )
    }

    const steps = props.availableSteps.map((stepName) => {
        const currentStep = stepNumberFromStepName(props.currentStep)
        const stepNumber = stepNumberFromStepName(stepName);
        return (
            <Box
                key={stepNumber}
                sx={{
                    borderRadius: '2px',
                    height: '2px',
                    backgroundColor: stepNumber <= currentStep ? '#071F3F' : '#D3D3D3',
                    flex: 1,
                }}
            />
        )
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '.5rem'
        }}>
            {steps}
        </Box>
    );
}

export function WizardView() {

    function numberFromParam(param: string | undefined) {
        const parsed: number = parseInt(param || "", 10)
        const result: number = Number.isNaN(parsed) ? 0 : parsed 
        return result
    }

    //const debug = useDebug({ name: "WizardView" })

    const navigate = useNavigate()

    // @fixme put this into jotai, so we don't need to handle url reloading; might help
    const urlParams = useParams()
    const applianceId: number = numberFromParam(urlParams.applianceId)
    const estateId: number =  numberFromParam(urlParams.estateId)

    // debug.log('applianceId', applianceId, urlParams)

    const mode: WizardMode = urlParams.applianceId === undefined ? 'create_estate' : 'edit_appliance'

    // debug.log('mode', mode)

    return (
        mode === 'edit_appliance' ?
            <WizardWithEstateAndAppliance applianceId={applianceId} mode={"edit_appliance"} />
            :
            <Wizard estateId={estateId} applianceId={0} mode={"create_estate"} />
    )
}

function WizardWithEstateAndAppliance({ applianceId, mode }) {
    const debug = useDebug({name: 'WizardWithEstateAndAppliance'});

    const appliance = useAppliance(applianceId, {
        refetchOnMount: false // @hotfix
    },'WizardWithEstateAndAppliance')
    const { status, data, error } = appliance
    debug.logObject('appliance',appliance)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (!data) { // should not happen
        return null
    }

    return (
        <Wizard applianceId={applianceId} estateId={data.estate_id} mode={mode} />
    )
}


function Wizard(props: { applianceId: number, estateId: number, mode: WizardMode }) {

    const navigate = useNavigate()

    const [currentStep, setCurrentStep] = React.useState<StepName>(props.mode === 'edit_appliance' ? 'step2' : 'step-welcome-0')

    const navigateBack = () => {
        if (currentStep === "step10") goToStep('step9')
        else if (currentStep === "step9") goToStep('step8')
        else if (currentStep === "step8") goToStep('step7')
        else if (currentStep === "step7") goToStep('step3')
        else if (currentStep === "step3") goToStep('step2')
        else if (currentStep === "step2") goToStep('step1') // if (props.mode === 'create_estate')
        else if (currentStep === 'step1') goToStep('step-welcome-0')
    }

    const goToStep = (stepName: StepName) => {
        // page will be changed so scroll to top of the screen
        window.scrollTo(0,0)

        setCurrentStep(stepName)
    }

    const [estateId, setEstateId] = React.useState(props.estateId) // 0
    const [applianceId, setApplianceId] = React.useState(props.applianceId) // 0

    function StepDebug({ children }) {
        return children
    }

    const AVAILABLE_STEPS: StepName[] = (
        props.mode === 'create_estate' ?
            ['step-welcome-0','step1', 'step2', 'step3', 'step7', 'step8', 'step9']
            :
            ['step-welcome-0','step1', 'step2', 'step3', 'step7', 'step8', 'step9']
    )

    return (
        <>
            <Container maxWidth="sm">

                <DebugWizard
                    applianceId={applianceId}
                    currentStep={currentStep}
                    estateId={estateId}
                    setApplianceId={setApplianceId}
                    setCurrentStep={setCurrentStep}
                    setEstateId={setEstateId}
                />

                <Box sx={{
                    display: 'flex',
                    gap: '1rem',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}>
                        <Typography
                            component={'div'}
                            sx={{
                                color: '#001F3F',
                                fontSize: '20px',
                                fontWeight: FONT_WEIGHT.medium,
                            }}
                        >
                            Průvodce nastavením
                        </Typography>
                        <IconButton onClick={() => {

                            // In case Wizard is opened from bookmark ...
                            navigate('/')
                        }}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>

                    <StepProgress
                        availableSteps={AVAILABLE_STEPS}
                        currentStep={currentStep} 
                    />
                </Box>

                {currentStep === 'step-welcome-0' &&
                    <StepDebug>
                        <WizardStepWelcome
                            nextStep={() => {
                                goToStep('step1')
                            }}
                        />
                    </StepDebug>
                }
                {currentStep === 'step1' &&
                    <StepDebug>
                        <WizardStep1
                            estateId={estateId}
                            setEstateId={setEstateId}
                            nextStep={() => {
                                goToStep('step2-prepare')
                            }}
                            goBack={navigateBack}
                        />
                    </StepDebug>
                }
                {currentStep === 'step2-prepare' &&
                    <WizardStep2Prepare
                        estateId={estateId}
                        applianceId={applianceId}
                        setApplianceId={setApplianceId}
                        nextStep={() => goToStep('step2')}
                    />
                }
                {currentStep === 'step2' &&
                    <StepDebug>
                        <WizardStep2
                            // setEstateId={setEstateId}
                            applianceId={applianceId}
                            estateId={estateId}
                            setApplianceId={setApplianceId}
                            goToStep={goToStep}
                            goBack={
                                props.mode === 'create_estate'
                                    ?
                                    navigateBack // to change name of estate ...
                                    :
                                    navigateBack // also allow going back so it is consistent with other forms ... undefined
                            }
                            mode={props.mode}
                        />
                    </StepDebug>
                }
                {currentStep === 'step3' &&
                    <StepDebug>
                        <WizardStep3
                            estateId={estateId}
                            applianceId={applianceId}
                            goToStep={goToStep}
                            goBack={navigateBack}
                        />
                    </StepDebug>}
                {currentStep === 'step7' &&
                    <StepDebug>
                        <WizardStep7 estateId={estateId}
                            applianceId={applianceId}
                            goToStep={goToStep}
                            goBack={navigateBack}
                        />
                    </StepDebug>
                }
                {currentStep === 'step8' &&
                    <StepDebug>
                        <WizardStep8
                            applianceId={applianceId}
                            estateId={estateId}
                            goToStep={goToStep}
                            goBack={navigateBack}
                        />
                    </StepDebug>
                }
                {currentStep === 'step9' &&
                    <StepDebug>
                        <WizardStep9
                            estateId={estateId}
                            applianceId={applianceId}
                            goToStep={goToStep}
                            goBack={navigateBack}
                        />
                    </StepDebug>
                }

                {currentStep === 'step10' &&
                    <StepDebug>
                        <NewAppliancePlanStep
                            estateId={estateId}
                            applianceId={applianceId}
                            goToStep={goToStep}
                            goBack={navigateBack}
                        />
                    </StepDebug>
                }
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}
