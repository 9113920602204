import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { AppliancePlan, AppliancePlanFormInput } from '../types'

export function useAppliancePlans(applianceId) {
    return useQuery({
        queryKey: ['appliancePlans-' + applianceId],
        queryFn: async () => {
            const { data } : { data : Array<AppliancePlan> } = await axios.get(
                '/api/appliance_plan/list?appliance_id=' + applianceId,
            )
            return data
        },
    })
}

export function useAppliancePlan(appliancePlanId) {
    return useQuery({
        queryKey: ['appliancePlan-' + appliancePlanId],
        queryFn: async () => {
            const { data } : { data : AppliancePlan } = await axios.get(
                '/api/appliance_plan/single?id=' + appliancePlanId,
            )
            return data
        },
    })
}

export const useCreateAppliancePlan = (applianceId, returnToList: (() => void) | ((appliancePlanId: number) => void )) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: AppliancePlanFormInput) => {            
            return axios.get(`/api/appliance_plan/create?appliance_id=${applianceId
                }&name=${data.name
                }&active=${data.active ? 'true' : 'false'}`
            )
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlans-' + applianceId] })
            returnToList(response.data.id)
        },
    })
}

export const useUpdateAppliancePlan = (appliancePlanId, applianceId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: AppliancePlanFormInput) => {
            return axios.get(`/api/appliance_plan/update?id=${appliancePlanId
                }&appliance_id=${applianceId
                }&name=${data.name
                }&active=${data.active ? 'true' : 'false'}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlans-' + applianceId] })
            queryClient.invalidateQueries({ queryKey: ['appliancePlan-' + appliancePlanId] })
            resetDrawer()
        },
    })
}

export const useToggleAppliancePlan = (appliancePlanId, applianceId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (active: boolean) => {
            return axios.get(`/api/appliance_plan/update?id=${appliancePlanId
                }&active=${active}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlans-' + applianceId] })
            queryClient.invalidateQueries({ queryKey: ['appliancePlan-' + appliancePlanId] })
            resetDrawer()
        },
    })
}

export const useDeleteAppliancePlan = (appliancePlanId, applianceId, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/appliance_plan/delete?id=${appliancePlanId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlans-' + applianceId] })
            returnToList()
        },
    })
}
