import React from "react";
import Button from "@mui/material/Button";
import { openWizardRoute } from "./utils";
import { useNavigate } from "react-router";

export function WizardButton({
    estateId = 0,
    applianceId = 0,
}: {
    estateId?: number,
    applianceId?: number
}) {

    const navigate = useNavigate()

    return (
        <Button
            onClick={() => {
                navigate(`/wizard/${estateId ? estateId : '0'}/${applianceId ? applianceId : ''}`)
            }}
            variant="contained"
        >
            Rychlé nastavení
        </Button>
    )

}
