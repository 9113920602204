import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { EstateIsReady } from '../types'

function EstateNotReadyCard({
    estateIsReady,
}: {
    estateIsReady: EstateIsReady,
}) {

    const [expand, setExpand] = useState<boolean>(false)
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customBeige.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customBeige.dark',
                    },
                }}
                onClick={() => setExpand(!expand)}
            >
                <Box sx={{
                    paddingRight: 1,
                    fontSize: '1.8rem',
                    lineHeight: 0,
                }}>
                    <ErrorOutlineRoundedIcon fontSize='inherit' color="warning"/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                    color: 'warning.main',
                }}>
                    <Box sx={{ fontSize: '0.875rem' }}>Nemovitost zatím není připravena k optimalizaci</Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                    color: 'warning.main',
                }}>
                    {expand ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
            </ButtonBase>
            {expand &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customBeige.main',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 4,
                    marginTop: -4,
                }}>
                    <Box sx={{ fontSize: '0.875rem', color: 'warning.main' }}>{estateIsReady.error}</Box>
                </Box>
            }
        </Box>
    )
}

export default EstateNotReadyCard
