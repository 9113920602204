import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

import { PowerElectricity, PowerElectricityType } from '../types'
import UpdatePowerElectricityForm from './forms/power-electricity/update'
import { convertFromKiloWattHoursPrice } from '../helpers'

function PowerElectricityCard({
    powerElectricity,
}: {
    powerElectricity: PowerElectricity,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const TYPE_MAP = {
        'PowerElectricity::Spot': 'Spotový',
        'PowerElectricity::Flat': 'Fixní',
    }
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customGrey.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, .03)',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customGrey.dark',
                    },
                }}
                onClick={() => setEdit(!edit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                }}>
                    <Box mb={0.5} fontSize={'1rem'}>{TYPE_MAP[powerElectricity.type]}</Box>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        {powerElectricity.type === PowerElectricityType.spot &&
                            <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                                Poplatky: {convertFromKiloWattHoursPrice(powerElectricity.power_fees[powerElectricity.power_fees.length -1]?.amount_cents)} Kč bez DPH/MWh
                            </Box>
                        }
                        {powerElectricity.type === PowerElectricityType.flat &&
                            <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                                Cena elektřiny: {convertFromKiloWattHoursPrice(powerElectricity.power_electricity[powerElectricity.power_electricity.length -1]?.amount_cents)} Kč bez DPH/MWh
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                
            </ButtonBase>
            {edit &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customGrey.light',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdatePowerElectricityForm
                        estateId={powerElectricity.estate_id}
                        powerElectricity={powerElectricity}
                        handleClose={() => setEdit(!edit)}
                    />
                </Box>
            }
        </Box>
    )
}

export default PowerElectricityCard
