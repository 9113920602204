import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import packageJson from './../../../package.json'


function ProfileView() {

    const domNode = document.getElementById('react-root')
    const userEmail = domNode?.getAttribute('user-email')

    return (
        <>
            <Container maxWidth="sm">
                {/* TODO: Abstract - used accross pages */}
                <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            href="/"
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        Účet
                    </Box>
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="settings"
                            disabled
                            component={Link}
                            to="/"
                        >
                            <SettingsRoundedIcon />
                        </IconButton>
                    </Box>
                </Box>
                {/* <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1rem',
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Jméno a příjmení
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    Franta Flinta
                </Box> */}
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                    marginTop: '1rem',
                 }}>
                    Email
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    {userEmail}
                </Box>
                {/* <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Subscription
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    paddingLeft: '0.4rem',
                 }}>
                    Roční
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    K obnovení dojde 25.9.2024
                </Box> */}
                
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 6,
                }}>
                    <Button
                        startIcon={<LockRoundedIcon fontSize='large' />}
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                    >
                        Odhlásit se
                    </Button>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box> */}
            </Container>
        </>
    )
}

export default ProfileView
