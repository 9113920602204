import { Dose } from "./types"

function padTo2Digits(num) {
    return String(num).padStart(2, '0')
}

export function printDoseTime(begins_at, ends_at) {
    if (!begins_at || !ends_at) return '-'
  
    const beginsAtDate = new Date(begins_at)
    const endsAtDate = new Date(ends_at)

    return `${padTo2Digits(beginsAtDate.getHours())
    }:${padTo2Digits(beginsAtDate.getMinutes())
    } - ${padTo2Digits(endsAtDate.getHours())
    }:${padTo2Digits(endsAtDate.getMinutes())
    }`
}

export function getHoursDate(hours: string) {  
    const date = new Date()
    const splitHours = hours.split(':')
    date.setHours(parseInt(splitHours[0]), parseInt(splitHours[1]))

    return date.toISOString()
}

export function getHoursAndMinutesString(dateIsoString: string) {  
    const date = new Date(dateIsoString)
    
    return `${padTo2Digits(date.getHours())
    }:${padTo2Digits(date.getMinutes())}`
}

export function getDoseTypeCounts(dose: Dose) {  
    const showerCount = dose.dose_time_frame_dose_type_junctions.find(
        doseType => doseType.dose_type_id == 1
    )?.doses_count ?? 0

    const bathCount = dose.dose_time_frame_dose_type_junctions.find(
        doseType => doseType.dose_type_id == 2
    )?.doses_count ?? 0
    
    return [showerCount, bathCount]
}

export function getHours(dateIsoString: string) {  
    const date = new Date(dateIsoString)
    
    return date.getHours()
}

export function getLengthInHours(beginningIsoString: string, endIsoString: string) {
    const beginningDate = new Date(beginningIsoString)
    const endDate = new Date(endIsoString)

    return (endDate.getHours()+(endDate.getMinutes()/60))-(beginningDate.getHours()+(beginningDate.getMinutes()/60))
}

export function printDate(isoDate: string) {
    const date = new Date(isoDate)
    // Get day, month, year, hours, and minutes from the date object
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
  
    // Return formatted date string
    return `${day}.${month}.${year} ${hours}:${minutes}`
}

export function convertFromCents(price: number):number {  
    if (!price) {
        return 0
    } else {
        return parseFloat((price/100).toFixed(5))
    }
}

export function convertFromKiloWattHoursPrice(price_kiloWatt: number):number {
    if (!price_kiloWatt) {
        return 0
    } else {
        const MEGAWATT = 1000
        return parseFloat((price_kiloWatt*MEGAWATT/100).toFixed(5))
    }
}

export function convertToCents(price: number | string | undefined):number {  
    if (!price) {
        return 0
    } else if (typeof price === 'string') {
        // Legacy rounding, now implemented on BE
        // return (parseFloat(price)*100).toFixed(5)
        return parseFloat(price)*100
    } else {
        return price*100
    }
}

export function convertToKiloWattHoursPrice(price_megaWatt: number | string | undefined):number {
    const MEGAWATT = 1000

    if (!price_megaWatt) {
        return 0
    } else if (typeof price_megaWatt === 'string') {
        return parseFloat(price_megaWatt)*100/MEGAWATT
    } else {
        return price_megaWatt*100/MEGAWATT
    }
}

/*
    USAGE:

        const schema = new DataDictionary<ApplianceFormInput, Appliance, ApplianceFormInput['blob']>()

        schema.add('id', 'id', 'number' )
        schema.add('isDependentOnHdo', 'is_dependent_on_hdo', 'boolean' )
        schema.add('lossPower','loss_power', 'number' )
        ...

        // blob
        schema.addBlob('dissipation', 'dissipation','number' )
        schema.addBlob('energyClass','energetic_class','string') //EnergyClass', )
        schema.addBlob('hotWaterMaxTemperature','hot_water_max_temperature', 'number' )
        ...

*/
export class DataDictionary<
    FormType extends object, // so Object.keys knows it can be iterated
    DataType,
    BlobType
> {
    schema: { [name in keyof FormType]: {
        name: keyof FormType,
        nameInRequest: keyof DataType,
        type: 'string' | 'number' | 'boolean',
    } }

    blobSchema: { [name in keyof FormType]: {
        name: keyof FormType,
        nameInRequest: keyof BlobType,
        type: 'string' | 'number' | 'boolean',
    } }

    constructor() {
        this.schema= {}
        this.blobSchema = {}
    }

    // add<T1 extends keyof FormType, T2 extends keyof DataType>(name: T1, nameInRequest: T2, type: 'string' | 'number' | 'boolean') {
    add(name: keyof FormType, nameInRequest: keyof DataType, type: 'string' | 'number' | 'boolean') {
        this.schema = this.schema || {}
        this.schema[name] = { name, nameInRequest, type }
    }

    addBlob(name: keyof FormType, nameInRequest: keyof BlobType, type: 'string' | 'number' | 'boolean') {
        this.blobSchema = this.blobSchema || {}
        this.blobSchema[name] = { name, nameInRequest, type }
    }

    toUrl<
        // To allow some data outside of form to be passed ...
        ExtraData = DataType
    >(endpoint: string, data: FormType, extraData?: Partial<ExtraData>) {

        let args: string[] = []
        let blob = {}

        // for each object in 
        if (Object.keys(data).length > 0) {
            for (const key of Object.keys(data)) {
                if (this.schema[key]) {
                    let value: string | number;

                    if (this.schema[key].type === 'boolean') {
                        value = typeof data[key] === 'boolean'? data[key] : data[key] === 'true'
                    } else if (this.schema[key].type === 'number') {
                        value = typeof data[key] === 'string' ? parseFloat(data[key]) : data[key] // @fixme why was this conversion needed?
                    } else {
                        value = `${data[key]}`
                    }

                    value = encodeURIComponent(value)

                    let nameInRequest = this.schema[key].nameInRequest

                    args.push(`${nameInRequest}=${value}`)

                } else if (this.blobSchema[key]) {

                    let nameInRequest = this.blobSchema[key].nameInRequest

                    blob[nameInRequest] = data[key]

                } else {
                    // unknown field ... should not happen
                }
            }
        }

        // For example to add `id`, which is not present in forms usually
        if(extraData) {
            for(const key of Object.keys(extraData)) {
                const value = encodeURIComponent(extraData[key])
                args.push(`${key}=${value}`)
            }
        }

        let url = endpoint
        url += '?'
        url += args.join('&')
        if(Object.keys(blob).length >  0) {
            url += `blob=${encodeURIComponent(JSON.stringify(blob))}`
        }
        return url
    }
}
