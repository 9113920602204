import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useAppliancePlanOverrides } from '../hooks/use-plan-override'
import useDrawer from '../hooks/use-drawer'
import { PlanOverrideType } from '../types'
import SwipeableTemporaryDrawer from '../components/swipeable-temporary-drawer'
import PlanOverrideSelector from './plan-override-selector'

function PlanOverrideButton({
    appliancePlanId,
    disabled,
}: {
    appliancePlanId: number | undefined,
    disabled?: boolean,
}) {

    const [drawerOpenOverrides, toggleDrawerOverrides, resetDrawerOverrides] = useDrawer()
    
    const { status, data, error } = useAppliancePlanOverrides(appliancePlanId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    const currentDate = new Date()

    const activeOverride = data.find(override => {
        const beginsAtDate = new Date(override.begins_at)
        const endsAtDate = new Date(override.ends_at)
        return beginsAtDate < currentDate && endsAtDate > currentDate
    })

    return (
        <>
            <Box sx={{
                flexBasis: '50%',
            }}>
                <ButtonBase 
                    component="div"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: activeOverride ? 'customGreen.main' : 'customGrey.main',
                        padding: 2,
                        borderRadius: 5,
                        "&.Mui-focusVisible": {
                            backgroundColor: activeOverride ? 'customGreen.dark' : 'customGrey.dark',
                        },
                    }}
                    onClick={toggleDrawerOverrides(true)}
                    disabled={disabled}
                >
                    <Box sx={{
                        paddingRight: 1.5,
                        fontSize: '1.5rem',
                        lineHeight: 0,
                    }}>
                        {activeOverride?.type == PlanOverrideType.party &&
                            <LocalFireDepartmentRoundedIcon fontSize='inherit'/>
                        }
                        {activeOverride?.type == PlanOverrideType.away &&
                            <BeachAccessRoundedIcon fontSize='inherit'/>
                        }
                        {!activeOverride &&
                            <LocalFireDepartmentRoundedIcon fontSize='inherit'/>
                        }
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        gap: 0.2,
                    }}>
                        <Typography component="div" sx={{ fontSize: '1rem' }}>
                            {activeOverride?.type == PlanOverrideType.party && 'Párty režim'}
                            {activeOverride?.type == PlanOverrideType.away && 'Režim dovolené'}
                            {!activeOverride && 'Párty/Dovolená'}
                        </Typography>
                        <Typography component="div" sx={{ fontSize: '0.875rem' }}>{activeOverride ? 'Zapnuto' : 'Vypnuto'}</Typography>
                    </Box>
                </ButtonBase>
            </Box>
            <SwipeableTemporaryDrawer
                open={drawerOpenOverrides}
                toggleDrawer={toggleDrawerOverrides}
            >
                <PlanOverrideSelector appliancePlanId={appliancePlanId}/>
            </SwipeableTemporaryDrawer>
        </>
    )
}

export default PlanOverrideButton
