import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { Dose, DoseFormInput } from '../types'

export function useDoses(appliancePlanId) {
    return useQuery({
        queryKey: ['doses-' + appliancePlanId],
        queryFn: async () => {
            const { data } : { data : Array<Dose> } = await axios.get(
                '/api/dose_time_frame/list?appliance_plan_id=' + appliancePlanId,
            )
            return data
        },
    })
}

export function useDose(doseId) {
    return useQuery({
        queryKey: ['dose-' + doseId],
        queryFn: async () => {
            const { data } : { data : Dose } = await axios.get(
                '/api/dose_time_frame/single?id=' + doseId,
            )
            return data
        },
    })
}


function createDose(appliancePlanId:number, data: Dose, selectedDay: number) {
    return axios.get(`/api/dose_time_frame/create?appliance_plan_id=${encodeURIComponent(appliancePlanId)
        }&begins_at=${encodeURIComponent(data.begins_at)
        }&ends_at=${encodeURIComponent(data.ends_at)
        }&day_of_week=${encodeURIComponent(selectedDay)
        }&doses=${encodeURIComponent(
            JSON.stringify(data.dose_time_frame_dose_type_junctions.map(
                timeFrame => ({
                    count: timeFrame.doses_count, dose_type_id: timeFrame.dose_type_id
                })
            ))
        )}`
    )
}

export function useDuplicateDosesToOtherDays(appliancePlanId, selectedDay: number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async function () {
            const tasks: Promise<any>[] = []

            const doses = await axios.get<Dose[]>(
                '/api/dose_time_frame/list?appliance_plan_id=' + encodeURIComponent(appliancePlanId),
            )            
            const currentDayDoses = doses.data.filter(dose => dose.day_of_week == selectedDay)
            currentDayDoses.forEach(copiedDose => {
                for(let day = 0; day < 7; day++) { // for each day of week
                    if(day !== selectedDay) {
                        tasks.push(createDose(appliancePlanId, copiedDose, day))
                    }
                }
            })

            return Promise.all(tasks)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['doses-' + appliancePlanId] })
        }
    })
}

export const useCreateDose = (appliancePlanId, selectedDay, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DoseFormInput) => {            
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/dose_time_frame/create?appliance_plan_id=${appliancePlanId
                }&begins_at=${beginsAt
                }&ends_at=${endsAt
                }&day_of_week=${selectedDay
                }&doses=[{"count": ${data.shower_count
                }, "dose_type_id": 1}, {"count": ${data.bath_count
                }, "dose_type_id": 2}]`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['doses-' + appliancePlanId] })
            returnToList()
        },
    })
}

export const useUpdateDose = (doseId, appliancePlanId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DoseFormInput) => {
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/dose_time_frame/update?id=${doseId
                }&appliance_plan_id=${appliancePlanId
                }&begins_at=${beginsAt
                }&ends_at=${endsAt
                }&doses=[{"count": ${data.shower_count
                }, "dose_type_id": 1}, {"count": ${data.bath_count
                }, "dose_type_id": 2}]`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['doses-' + appliancePlanId] })
            queryClient.invalidateQueries({ queryKey: ['dose-' + doseId] })
            resetDrawer()
        },
    })
}

export const useDeleteDose = (doseId, appliancePlanId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/dose_time_frame/delete?id=${doseId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['doses-' + appliancePlanId] })
        },
    })
}
