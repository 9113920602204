import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

// TODO: Need to delete when logging out
export const selectedEstateIdAtom = atomWithStorage<number | null>('selectedEstateId', null)

export const selectedAppliancePlansAtom = atom<AppliancePlans>({})

interface AppliancePlans {
    [key:string]: number
}
