import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import packageJson from '../../../package.json'
import CreateButton from '../components/create-button'
import useDrawer from '../hooks/use-drawer'
import SwipeableTemporaryDrawer from '../components/swipeable-temporary-drawer'
import { useAppliance } from '../hooks/use-appliance'
import SelectedAppliancePlan from '../components/selected-appliance-plan'
import ApplianceSettingsTabs from '../components/appliance-settings-tabs'
import PlugNotReadyCard from '../components/plug-not-ready-card'
import { WizardButton } from './wizard/wizard-button'

function ApplianceView() {

    let { applianceId } = useParams()

    const [drawerOpenSettings, toggleDrawerSettings, resetDrawerSettings] = useDrawer()

    const { status, data, error } = useAppliance(applianceId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }
    
    return (
        <>
            <Container maxWidth="sm">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem'
                }}>
                    <Box display="flex" alignContent="center">
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                component={Link}
                                to="/"
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            {'Bojler '+data.id}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '1rem',
                        }}>
                            <Box>
                                <IconButton
                                    color="primary"
                                    aria-label="settings"
                                    onClick={toggleDrawerSettings(true)}
                                >
                                    <SettingsRoundedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display : 'flex', justifyContent: 'right', marginBottom: '1rem'}}>
                        <WizardButton estateId={data.estate_id} applianceId={applianceId} />
                    </Box>
                </Box>
                {/* TODO - Connect to state */}
                {false &&
                    <Box sx={{
                        marginTop: 1,
                        marginBottom: 2,
                    }}>
                        <CreateButton text='Vytvořit plán spotřeby' onClick={() => console.log('clicked')} />
                    </Box>
                }
                <Box sx={{
                        marginTop: 1,
                        marginBottom: 2,
                    }}>
                    <PlugNotReadyCard
                        applianceId={data.id}
                        toggleSettings={toggleDrawerSettings(true)}
                    />
                </Box>
                <SelectedAppliancePlan applianceId={applianceId}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                }}>
                    <Button
                        startIcon={<HistoryRoundedIcon fontSize='large' />}
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                        disabled
                    >
                        Historie
                    </Button>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
            <SwipeableTemporaryDrawer
                open={drawerOpenSettings}
                toggleDrawer={toggleDrawerSettings}
            >
                <ApplianceSettingsTabs appliance={data} resetDrawer={resetDrawerSettings} />
            </SwipeableTemporaryDrawer>
        </>
    )
}

export default ApplianceView
