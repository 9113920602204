import React from "react";
import ElectricalServicesOutlinedIcon
    from "@mui/icons-material/ElectricalServicesOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router";

import { SharedStepProps } from "../types";
import { useCreateAppliancePlan } from "../../../hooks/use-appliance-plan";
import type { AppliancePlanFormInput } from "../../../types";
import CustomButton from "../../../components/custom-button";
import { BottomButtons, FormControlWithLabelWrapper, Hint, StepIcon } from "./wizard-common";
import { PreviousStepButton } from "../components";
import { Text } from './wizard-common'

export function NewAppliancePlanStep({
                          goBack,
                          estateId,
                          applianceId,
                      }: SharedStepProps & {
    applianceId: number,
    estateId: number,
}) {
    const navigate = useNavigate()

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            active: true,
        },
    })

    const mutation = useCreateAppliancePlan(applianceId, (appliancePlanId) => {
        navigate(`/estate/${estateId}/appliance/${applianceId}/plan/${appliancePlanId}`)
    })

    const onSubmit: SubmitHandler<AppliancePlanFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
        }}>

            <StepIcon icon={<ElectricalServicesOutlinedIcon/>} color={"#ffecdb"} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                }}>

                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap:'.75rem',
                            margin: '0 0 1rem 0', 
                        }}
                    >
                        <Text
                            //isHeader={true}
                        >
                            Nyní vyplňte časy, kdy se obvykle sprchujete či koupete
                        </Text>
                        <Hint>
                            Aplikace ohřívá vodu co nejlevněji, což je typicky až těsně před sprchováním. Je tedy lepší vyplnit časy sprchování o trochu dřív, než kdy se skutečně sprchujete, aby byla teplá voda už připravená. Přesnější plánování ale vede k lepší optimalizaci.
                        </Hint>
                    </Box>

                    <FormControlWithLabelWrapper
                        label={"Jméno plánu"}
                    >
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => <TextField id="estate-name" label="Název plánu" variant="standard" required {...field} />}
                        />
                    </FormControlWithLabelWrapper>

                    <BottomButtons>
                        <PreviousStepButton onClick={() => {
                            goBack()
                        }}>
                            Zpět
                        </PreviousStepButton>

                        <CustomButton
                            text="Nastavit sprchování"
                            type="submit"
                            disabled={mutation.isPending}
                        />
                    </BottomButtons>

                    {mutation.isError ? (
                        <div>Došlo k chybě: {mutation.error.message}</div>
                    ) : null}

                </Box>
            </form>
        </Box>
    )
}
