import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import { Appliance } from '../types'
import { useAppliance } from '../hooks/use-appliance'

function round(x: number, decimalPlaces: number) {
    
    // round the number
    const multiplier = Math.pow(10, decimalPlaces)
    const num = Math.round(x * multiplier) / multiplier

    // make sure rounding doesn't add digits
    const numberAsString = `${num}`.replace(/\.(\d\d).*/, ".$1")

    let [whole, decimals] = numberAsString.split(".")

    if (!decimals) {
        decimals = "" // we want to always add trailing zeroes
    }

    let result = ''
    result = whole
    result += '.'

    // zero fill the remainign numbers
    for (let i = 0; i < decimalPlaces; i++) {
        result += i < decimals.length ? decimals[i] : "0"
    }

    return result
}

function Temperature({ applianceId }) {

    const { status, data, error } = useAppliance(applianceId)

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: 'center',
                }}
            >
                <DeviceThermostatRoundedIcon fontSize='inherit' />
                <Box sx={{
                    minWidth: '61px' // to make text not pop; there is not gonna be 3 digit temperature (I hope)
                }}>
                    {
                        // true &&
                        (status === 'pending') &&
                        <CircularProgress size={'15px'} />
                    }
                    {
                        // ( false ? ( 
                        (status !== 'pending' && data !== undefined && !error && data.actual_temperature !== undefined ? (
                            <Box
                                sx={{
                                    /* font-size: 24px; */
                                    letterSpacing: "-1px",
                                    /* font-weight: 500; */
                                    // fontSize: '31px',
                                    fontSize: '20px',
                                }}
                            >{round(data.actual_temperature, 1)}°C</Box>
                        ) : '')}
                </Box>
            </Box>
        </>
    )
}

function BoilerCard({
    appliance,
}: {
    appliance: Appliance,
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'customGreen.main',
                padding: 2,
                borderRadius: 5,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customGreen.dark',
                },
                justifyContent: 'space-between',
            }}
            component={Link}
            to={`appliance/${appliance.id}`}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Box sx={{
                    margin: '0 24px 0 0',
                }}>
                    {'Bojler ' + appliance.id}
                </Box>
                <Box sx={{
                    paddingRight: '2rem',
                    fontSize: '1.8rem',
                    lineHeight: 0,
                }}>
                    <Temperature applianceId={appliance.id} />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {/* <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 0.2,
                }}>
                    
                    <Box sx={{ fontSize: '0.875rem' }}>Objem: {appliance.blob?.volume+' l'} • Výkon: {appliance.input_power+' W'}</Box>
                </Box> */}
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    <ChevronRightRoundedIcon fontSize='inherit' />
                </Box>
            </Box>
        </ButtonBase>
    )
}

export default BoilerCard
