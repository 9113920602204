import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import GiteRoundedIcon from '@mui/icons-material/GiteRounded'

import PlanDetails from '../components/plan-details'
import { useAppliancePlan } from '../hooks/use-appliance-plan'

function PlanView() {

    let { estateId, applianceId, planId } = useParams()

    const { status, data, error } = useAppliancePlan(planId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Container maxWidth="sm">
                <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            component={Link}
                            to={'/estate/'+estateId+'/appliance/'+applianceId}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        {data.name}
                    </Box>

                    {/* temporary solution - to be able to get back from /wizard to homepage (wizard redirects to plan) */}
                    <IconButton
                        color="primary"
                        aria-label="back"
                        component={Link}
                        to={'/'}
                    >
                        {/* icon which symbolizes "home" icon */}
                        <GiteRoundedIcon />
                    </IconButton>
                </Box>
                <PlanDetails planId={planId} />
            </Container>
        </>
    )
}

export default PlanView
