import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAtom } from "jotai/react";
import { atom } from 'jotai'

import { BottomButtons, FormControlWithLabelWrapper, Hint } from "../wizard-common";
import {
    DISTRIBUTION_FORM_SUPPLIERS,
    DISTRIBUTION_RATE_TABLE,
    DistributionElectricity,
    DistributionElectricityFormInput,
    DistributionElectricity_Supplier,
    supplierHasEAN,
    supplierHasHDO,
} from "../../../../types";
import { PreviousStepButton } from "../../components";
import CustomButton from "../../../../components/custom-button";
import {
    useCreateDistributionElectricity_partial,
    useDistributionElectricity,
    useUpdateDistributionElectricity_partial
} from "../../../../hooks/use-distribution-electricity";
import { StepName } from "../../types";
import { useDebug } from "../../../debug";
import { useAppliance, useUpdateAppliance_partial } from "../../../../hooks/use-appliance";

const distributionFormWarnings = atom<string>('')

type DistributorFormFields = (
    // To store HDO we save it with another backend api, appliance, not distribution_electricity
    Omit<DistributionElectricityFormInput, 'has_manual_hdo'>
    // & {
    //     appliance__is_dependent_on_hdo?: boolean
    // }
);

export function DistributorForm({
    applianceId,
    estateId,
    distributionElectricityId,
    goBack,
    goToStep,
}: {
    applianceId: number,
    estateId: number,
    distributionElectricityId: number | undefined | null,
    goBack: () => void;
    goToStep: (stepName: StepName) => void;
}) {
    const [warnings, setWarnings] = useAtom(distributionFormWarnings)

    const { status, data, error } = useAppliance(applianceId, {
        refetchOnMount: false // @hotfix infinite refetch caused by mounts triggering it
    })

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            {distributionElectricityId ? // can be null
                <Update
                    applianceId={applianceId}
                    estateId={estateId}
                    distributionElectricityId={distributionElectricityId}

                    // reset jotai value when going back/forward

                    goBack={() => {
                        setWarnings("")
                        goBack()
                    }}
                    goToStep={(stepName) => {
                        setWarnings("")
                        goToStep(stepName)
                    }}
                    isDependentOnHdo={data.is_dependent_on_hdo}
                />
                :
                <Create
                    applianceId={applianceId}
                    estateId={estateId}

                    // reset jotai value when going back/forward

                    goBack={() => {
                        setWarnings("")
                        goBack()
                    }}
                    goToStep={(stepName) => {
                        setWarnings("")
                        goToStep(stepName)
                    }}
                    isDependentOnHdo={data.is_dependent_on_hdo}
                />
            }
        </>
    )
}

function Create({
    applianceId,
    estateId,
    goBack,
    goToStep,
    isDependentOnHdo,
}: {
    applianceId: number,
    estateId: number,
    goBack: () => void,
    goToStep: (stepName: StepName) => void;
    isDependentOnHdo: boolean,
}) {
    const [warnings, setWarnings] = useAtom(distributionFormWarnings)

    const mutation = useCreateDistributionElectricity_partial(estateId, (data) => {
        setWarnings(data.warnings || "")
        if(!data.warnings) {
            goToStep('step9')
        }
    })

    const mutationAppliance = useUpdateAppliance_partial(applianceId, estateId, () => { })

    const onSubmit: SubmitHandler<DistributorFormFields> = data => {
        mutation.mutate(data)
        // mutationAppliance.mutate({
        //     isDependentOnHdo: data.appliance__is_dependent_on_hdo
        // })
    }

    return (
        <Form
            goBack={goBack}
            onSubmit={onSubmit}
            disableSubmit={mutation.isPending}
            isDependentOnHdo={isDependentOnHdo}
            warnings={warnings}
        />
    )
}

function Update({
    applianceId,
    distributionElectricityId,
    estateId,
    goBack,
    goToStep,
    isDependentOnHdo,
}: {
    applianceId: number,
    distributionElectricityId: number,
    estateId: number,
    goBack: () => void,
    goToStep: (stepName: StepName) => void;
    isDependentOnHdo: boolean
}) {

    const [warnings, setWarnings] = useAtom(distributionFormWarnings)

    const { status, data, error } = useDistributionElectricity(distributionElectricityId)
    const mutation = useUpdateDistributionElectricity_partial(distributionElectricityId, (data) => {
        setWarnings(data.warnings || "")
        if(!data.warnings) {
            goToStep('step9')
        }
    })

    const mutationAppliance = useUpdateAppliance_partial(applianceId, estateId, () => { })

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    const onSubmit: SubmitHandler<DistributorFormFields> = data => {
        //alert(`Updated ${data.id}`)
        mutation.mutate(data)
        // mutationAppliance.mutate({
        //     isDependentOnHdo: data.appliance__is_dependent_on_hdo
        // })
    }

    return (
        <Form
            isDependentOnHdo={isDependentOnHdo}
            data={data}
            goBack={goBack}
            onSubmit={onSubmit}
            disableSubmit={mutation.isPending}
            warnings={warnings}
        />
    )
}

function Form({
    onSubmit,
    data, // initial data
    goBack,
    disableSubmit,
    isDependentOnHdo,
    warnings,
}: {
    onSubmit: SubmitHandler<DistributorFormFields>,
    data?: DistributionElectricity,
    goBack: () => void,
    disableSubmit: boolean,
    isDependentOnHdo: boolean,
    warnings: string,
}) {

    const debug = useDebug({ name: 'DistributorForm' });

    debug.log('render data = ', data)

    const rhf = useForm<DistributorFormFields>({
        defaultValues: {
            supplier: data?.supplier || '',
            distribution_rate: data?.distribution_rate || '',
            // appliance__is_dependent_on_hdo: isDependentOnHdo,
            // has_manual_hdo: data?.has_manual_hdo ?? true,

            //
            // Will be determined by server
            //
            // low_price_cents: data?.low_price_cents || '',
            // high_price_cents: data?.high_price_cents || '',

            signal_code: data?.signal_code || '',
            ean: data?.ean || '',
        },
    })
    const { control, handleSubmit, watch } = rhf
    debug.logObject('rhf', rhf)

    // const has_manual_hdo = watch('appliance__is_dependent_on_hdo');
    const supplier: DistributorFormFields['supplier'] | undefined = watch('supplier')
    const distribution_rate: DistributorFormFields['distribution_rate'] | undefined = watch('distribution_rate')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
            }}>

                <FormControlWithLabelWrapper
                    label={"Vyberte Vašeho distributora."}
                >
                    <Controller
                        name="supplier"
                        control={control}
                        render={({ field }) =>
                            <Select
                                // labelId="appliance-energy-class-label"
                                label="Distributor"
                                // id="appliance-energy-class"
                                {...field}
                            >
                                {DISTRIBUTION_FORM_SUPPLIERS.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControlWithLabelWrapper>

                {/* <FormControlWithLabelWrapper
                    label={'Máte zásuvku trvale pod proudem, nebo je pod proudem pouze při "nočním proudu" (nízký tarif)'}
                >
                    <Controller
                        name="appliance__is_dependent_on_hdo"
                        control={control}
                        render={({ field }) =>
                            <Select
                                // labelId="appliance-energy-class-label"
                                //label="Distributor"
                                // id="appliance-energy-class"
                                {...field}
                            >
                                {[
                                    { name: 'Jen při nočním proudu', id: true },
                                    { name: 'Trvale pod proudem', id: false },
                                ].map(({ name, id }) => (
                                    <MenuItem key={String(id)} value={String(id)}>{name}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControlWithLabelWrapper> */}

                <FormControlWithLabelWrapper
                    label={"Vyberte distribuční sazbu."}
                >
                    <Controller
                        name="distribution_rate"
                        control={control}
                        render={({ field }) =>
                            <Select
                                // labelId="appliance-energy-class-label"
                                label="Distribuční sazba"
                                // id="appliance-energy-class"
                                {...field}
                            >
                                {DISTRIBUTION_RATE_TABLE.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControlWithLabelWrapper>


                <DistributionRate
                    supplier={supplier}
                    distribution_rate={distribution_rate}
                    control={control}
                    warnings={warnings}
                />

                <BottomButtons>
                    <PreviousStepButton onClick={() => {
                        goBack()
                    }}>
                        Zpět
                    </PreviousStepButton>

                    <CustomButton
                        // text="Vytvořit"
                        text="Pokračovat"
                        type="submit"
                        disabled={disableSubmit}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}

function WhereToFindHDO({
    supplier
}: {
    supplier: DistributionElectricity_Supplier
}) {
    // 'https://www.predistribuce.cz/cs/potrebuji-zaridit/zakaznici/stav-hdo/'
    return (
        <Box sx={{}}>
            {/* {
                // supplier === 'ČEZ' && <a href={"https://www.cezdistribuce.cz/cs/pro-zakazniky/spinani-hdo.html"} target={"_blank"}>Kde najít povel a kód HDO</a>
                supplier === 'ČEZ' && <a href={"https://dip.cezdistribuce.cz/irj/portal/anonymous/casy-spinani/"} target={"_blank"}>Zkuste vyhledat své HDO podle EAN</a>
            } */}
            {supplier === 'PRE' && <a href={"https://www.predistribuce.cz/cs/potrebuji-zaridit/zakaznici/stav-hdo"} target={"_blank"}>Kde najít kód HDO</a>}
            {supplier === 'EG.D' && <a href={"https://www.egd.cz/jak-vyhledat-kod-hdo"} target={"_blank"}>Kde najít povel a kód HDO</a>}
        </Box>
    )
}

function DistributionRate({
    control,
    supplier,
    distribution_rate,
    warnings,
}: {
    control: Control<DistributorFormFields, any>,
    supplier: DistributorFormFields['supplier'],
    distribution_rate: DistributorFormFields['distribution_rate']
    warnings?: string,
}) {
    const debug = useDebug({ name: 'Tariff' })

    // These tarifs do not have HDO (https://chytrybojler.cz/tutorials/)
    if (distribution_rate === 'D01d' || distribution_rate === 'D02d') return;

    // 'https://www.predistribuce.cz/cs/potrebuji-zaridit/zakaznici/stav-hdo/'

    debug.log('supplier', supplier)

    // @fixme
    // Using `display: none` solves this RHF bug:
    //  select picks another value -> this function returns other input -> in render the field has correct `name` but `value` of previous field
    //
    return (
        <Box>
            <Box sx={{ display: supplierHasEAN(supplier) ? 'block' : 'none' }}>
                <FormControlWithLabelWrapper
                    label={"EAN"}
                >
                    <Hint>EAN najdete ve svém vyúčtování elektřiny, použijeme jej pro zjištění časů vašeho nočního proudu</Hint>
                    <WhereToFindHDO supplier={supplier} />

                    <Controller
                        name="ean"
                        control={control}
                        render={({ field }) => {
                            debug.logObject('CZE -input-field', field)
                            return <TextField
                                // id="appliance-volume"
                                // label="Počet osob"
                                variant="standard"
                                type="text"
                                required={supplierHasEAN(supplier)}
                                disabled={!(supplierHasEAN(supplier))}
                                {...field}
                            />
                        }}
                    />
                </FormControlWithLabelWrapper>
            </Box>
            <Box sx={{ display: supplierHasHDO(supplier) ? 'block' : 'none' }}>
                <FormControlWithLabelWrapper
                    label={"Kód HDO"}
                >
                    <Hint>Pro jižní Čechy je to třímístné číslo. Pro jižní Moravu kombinace písmen A, B, P a čísel, např. A1B8P1.</Hint>
                    <WhereToFindHDO supplier={supplier} />

                    <Controller
                        name="signal_code"
                        control={control}
                        render={({ field }) => {
                            debug.logObject('PRE -input-field', field)
                            return <TextField
                                // id="appliance-volume"
                                // label="Počet osob"
                                variant="standard"
                                type="text"
                                required={supplierHasHDO(supplier)}
                                disabled={!(supplierHasHDO(supplier))}
                                {...field}
                            />
                        }
                        }
                    />
                </FormControlWithLabelWrapper>
            </Box>

            <Box sx={{color: '#f70500', margin: '1.5rem 0 0 0'}}>{warnings}</Box>
        </Box>
    )
}
