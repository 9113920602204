import React from "react"
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import type { Dose, DoseFormInput } from "../../../types"
import CustomButton from "../../custom-button"
import { DOSES_IN_PICKER } from "../../../constants"
import { useUpdateDose } from "../../../hooks/use-dose"
import FormRow from "../helpers/form-row"
import { getDoseTypeCounts } from "../../../helpers"

export default function UpdateDoseForm({
    appliancePlanId,
    dose,
    handleClose,
}: {
    appliancePlanId: number,
    dose: Dose,
    handleClose: Function,
}) {
    const [showerCount, bathCount] = getDoseTypeCounts(dose)
    
    const { control, handleSubmit } = useForm({
        defaultValues: {
            begins_at: dayjs(dose.begins_at),
            ends_at: dayjs(dose.ends_at),
            shower_count: showerCount,
            bath_count: bathCount,
        },
    })

    const mutation = useUpdateDose(dose.id, appliancePlanId, handleClose)

    const onSubmit: SubmitHandler<DoseFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="begins_at"
                            control={control}
                            render={({ field }) =>
                                <TimePicker label="Začátek" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="ends_at"
                            control={control}
                            render={({ field }) =>
                                <TimePicker label="Konec" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                </FormRow>
                <FormRow>
                    <FormControl variant="standard" sx={{ flexGrow: 1 }}>
                        <InputLabel id="dose-shower-count-label">Počet sprchování</InputLabel>
                        <Controller
                            name="shower_count"
                            control={control}
                            render={({ field }) =>
                                <Select
                                    labelId="dose-shower-count-label"
                                    id="dose-shower-count"
                                    {...field}
                                >
                                    {DOSES_IN_PICKER.map(count => (
                                        <MenuItem key={count} value={count}>{count}</MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ flexGrow: 1 }}>
                        <InputLabel id="dose-bath-count-label">Počet koupání</InputLabel>
                        <Controller
                            name="bath_count"
                            control={control}
                            render={({ field }) =>
                                <Select
                                    labelId="dose-bath-count-label"
                                    id="dose-bath-count"
                                    {...field}
                                >
                                    {DOSES_IN_PICKER.map(count => (
                                        <MenuItem key={count} value={count}>{count}</MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    </FormControl>
                </FormRow>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
